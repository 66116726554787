/**
 * Load Microsoft Ads event listeners
 * */

// Pixel load event listener
jQuery(document).on("wpmLoadPixels", function () {

	if (wpmDataLayer?.pixels?.bing?.uet_tag_id && !wpmDataLayer?.pixels?.bing?.loaded) {
		if (wpm.canIFire("ads", "microsoft-ads")) wpm.load_bing_pixel()
	}
})

// add-to-cart event
jQuery(document).on("wpmAddToCart", function (event, product) {

	try {
		if (!wpmDataLayer?.pixels?.bing?.loaded) return

		window.uetq.push("event", "", {
			ecomm_pagetype: "cart",
			ecomm_prodid  : product.dyn_r_ids[wpmDataLayer.pixels.bing.dynamic_remarketing.id_type],
		})
	} catch (e) {
		console.error(e)
	}
})

// view product event
jQuery(document).on("wpmViewItem", function (event, product) {

	try {
		if (!wpmDataLayer?.pixels?.bing?.loaded) return

		window.uetq.push("event", "", {
			ecomm_pagetype: "product",
			ecomm_prodid  : product.dyn_r_ids[wpmDataLayer.pixels.bing.dynamic_remarketing.id_type],
		})
	} catch (e) {
		console.error(e)
	}
})

// view category event
jQuery(document).on("wpmCategory", function () {

	try {
		if (!wpmDataLayer?.pixels?.bing?.loaded) return

		window.uetq.push("event", "", {
			ecomm_pagetype: "category",
		})
	} catch (e) {
		console.error(e)
	}
})

// view search event
jQuery(document).on("wpmSearch", function () {

	try {
		if (!wpmDataLayer?.pixels?.bing?.loaded) return

		window.uetq.push("event", "", {
			ecomm_pagetype: "searchresults",
		})
	} catch (e) {
		console.error(e)
	}
})

// view order received page event
// https://help.ads.microsoft.com/#apex/ads/en/60118/-1
jQuery(document).on("wpmOrderReceivedPage", function () {

	try {
		if (!wpmDataLayer?.pixels?.bing?.loaded) return

		window.uetq.push("event", "purchase", {
			ecomm_pagetype: "purchase",
			ecomm_prodid  : wpm.bing_purchase_ecomm_prodids(),
			revenue_value : wpmDataLayer.order.value_filtered,
			currency      : wpmDataLayer.order.currency,
			items         : wpm.bing_purchase_items(),
		})

	} catch (e) {
		console.error(e)
	}
})

